import { render, staticRenderFns } from "./ProductComponentCategory.vue?vue&type=template&id=45ed3cc3&scoped=true&"
import script from "./ProductComponentCategory.vue?vue&type=script&lang=js&"
export * from "./ProductComponentCategory.vue?vue&type=script&lang=js&"
import style0 from "./ProductComponentCategory.vue?vue&type=style&index=0&id=45ed3cc3&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "45ed3cc3",
  null
  
)

export default component.exports